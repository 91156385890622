import * as actionTypes from "../actionTypes";

/**
 * 
 * @param {*} payload 
 * payload =  {
    open: true,
    title: "",
    content: "",
    onClose: () => {},
  }
 */
export function setInfoDialog(payload) {
  return function (dispatch) {
    dispatch({ type: actionTypes.INFO_DIALOG, payload });
  };
}

export function resetInfoDialog() {
  return function (dispatch) {
    dispatch({ type: actionTypes.INFO_DIALOG_RESET });
  };
}

/**
 * 
 * @param {*} payload 
 * payload =  {
    open: true,
    title: "",
    content: "",
    onClose: () => {},
    onOk: () => {},
  }
 */
export function setActionDialog(payload) {
  return function (dispatch) {
    dispatch({ type: actionTypes.ACTION_DIALOG, payload });
  };
}

export function resetActionDialog() {
  return function (dispatch) {
    dispatch({ type: actionTypes.ACTION_DIALOG_RESET });
  };
}

export function setActionDialogState(payload) {
  return function (dispatch) {
    dispatch({ type: actionTypes.ACTION_DIALOG_STATE, payload });
  };
}
