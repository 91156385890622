import React, { Component } from "react";
import PropTypes from "prop-types";
import { matchPath } from "react-router-dom";

import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import BreadcrumbBar from "./BreadcrumbBar";
import { adminRoutes, userRoutes } from "./routes";
import { ROUTE_TITLES } from "constants/ROUTE_TITLES";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import { Box } from "@material-ui/core";
import Scrollbars from "react-custom-scrollbars";

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      open: true,
    };
    this.originalTitle = document.title;
  }

  handleTitleNaming = () => {
    if (
      !this.props.location ||
      !this.props.location.pathname ||
      !ROUTE_TITLES[this.props.location.pathname]
    ) {
      //If route is not found, UI always redirect to AWS EC2 Management
      document.title = "SESTO | " + ROUTE_TITLES["/instances/list"];
      //For dynamic parameter url
      const paramUrls = Object.keys(ROUTE_TITLES).filter((v) =>
        v.includes(":")
      );
      if (!paramUrls?.length) {
        return;
      }

      for (const paramUrl of paramUrls) {
        if (matchPath(this.props.location.pathname, { path: paramUrl })) {
          document.title = "SESTO | " + ROUTE_TITLES[paramUrl];
          return;
        }
      }
      return;
    }
    document.title = "SESTO | " + ROUTE_TITLES[this.props.location.pathname];
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };

  componentDidMount() {
    this.handleTitleNaming();
    if (navigator.platform.indexOf("Win") > -1) {
    }
    window.addEventListener("resize", this.resizeFunction);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname === prevProps.location.pathname) {
      return;
    }
    this.handleTitleNaming();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  render() {
    return (
      <>
        <Header
          handleDrawerToggle={this.handleDrawerToggle}
          handleDrawerClose={
            this.state.open ? this.handleDrawerClose : this.handleDrawerOpen
          }
        />
        <Box className="d-flex" paddingTop={["58px", "64px"]}>
          <Sidebar
            adminRoutes={adminRoutes}
            userRoutes={userRoutes}
            logoText={"SESTO"}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color="#FFFFFF"
            classNames="drawer"
            isCollapsed={!this.state.open}
          />

          <div className="w-100">
            <Box component={Scrollbars} height="94vh !important">
              <BreadcrumbBar />
              <AuthenticatedRoutes />
            </Box>
          </div>
        </Box>
      </>
    );
  }
}

AppLayout.propTypes = {
  location: PropTypes.object.isRequired,
};

export default AppLayout;
