import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Menu,
  IconButton,
  Avatar,
  Box,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";

import { localStorageHelper } from "utils/StorageHelper";
import { bindActionCreators } from "redux";
import * as loginActions from "redux/actions/loginActions";
import { withRouter } from "react-router-dom";

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      name: "hai",
      labelWidth: 0,
      anchorEl: null,
      logginStatus: true,
    };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
      "mousewheel",
    ];
  }

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    this.warnTimeout = setTimeout(this.warn, 16 * 1000);
    this.logoutTimeout = setTimeout(this.logout, 60 * 10000);
  }

  resetTimeout = () => {
    this.clearTimeout();
    this.setTimeout();
  };

  logout = () => {
    this.setState({ logginStatus: false });
    this.handleLogout();
  };

  destroy() {
    this.clearTimeout();
    for (const i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleCloseProfile = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    localStorageHelper.clear();

    this.props.loginActions.logout();
    this.props.history.push("/#/login");
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  render() {
    const { anchorEl } = this.state;
    const openProfileMenu = Boolean(anchorEl);

    return (
      <>
        <IconButton
          aria-owns={openProfileMenu && "menu-appbar"}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
        >
          <Avatar src={""} />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openProfileMenu}
          onClose={this.handleCloseProfile}
          MenuListProps={{
            className: "d-flex flex-column",
          }}
        >
          <Box className="d-flex justify-content-center align-items-center p-2">
            <Avatar src={""} size="large" className="m-2" />
            <div className="p-1">
              <Typography variant="h3" className="mb-0">
                {this.props.user}
              </Typography>
              <Typography className="text-theme">{this.props.email}</Typography>
            </div>
          </Box>
          <Divider className="mb-2" />
          <Button variant="text" color="inherit" onClick={this.handleLogout}>
            Logout
          </Button>
        </Menu>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth?.userName,
    email: state.auth?.email,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginActions: bindActionCreators(loginActions, dispatch),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withRouter(HeaderLinks)
);
