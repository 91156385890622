import React, { Suspense } from "react";
import {
  LinearProgress as LinearProgressComponent,
  Box,
} from "@material-ui/core";

const LinearProgress = () => (
  <Box marginTop="300px" className="p-5 w-50" marginLeft="25%">
    <LinearProgressComponent />
  </Box>
);

//#region Lazy loaded imports
//Users routes
const Users = React.lazy(() => import("Components/Admin/Users/UsersList"));
const CreateUser = React.lazy(() =>
  import("Components/Admin/Users/HandleUser")
);
const EditUser = React.lazy(() =>
  import("Components/Admin/Users/HandleUserHome")
);
const DeleteUser = React.lazy(() =>
  import("Components/Admin/Users/DeleteUser")
);

// AWS EC2 Management Routes
const Instances = React.lazy(() =>
  import("Components/Ec2Dashboard/Instances/InstanceList")
);

// Simulation Routes
const Simulations = React.lazy(() =>
  import("Components/Simulation/SimulationsList")
);
const SetUpSimulation = React.lazy(() => import("Components/Simulation/SetUp"));
const EditSimulation = React.lazy(() => import("Components/Simulation/Edit"));
const ConfigureSimulation = React.lazy(() =>
  import("Components/Simulation/Configuration")
);

// AMI Management Routes
const AMIs = React.lazy(() => import("Components/AmiDashboard/Amis/AmiList"));

// Billing Routes
const Billing = React.lazy(() =>
  import("Components/BillingDashboard/BillingList")
);

const BehaviouralTest = React.lazy(() =>
  import("Components/BehavioralTest/BehavioralTest")
)

export const UsersSuspense = (props) => (
  <Suspense fallback={<LinearProgress />}>
    <Users {...props} />
  </Suspense>
);

export const CreateUserSuspense = (props) => (
  <Suspense fallback={<LinearProgress />}>
    <CreateUser {...props} />
  </Suspense>
);

export const EditUserSuspense = (props) => (
  <Suspense fallback={<LinearProgress />}>
    <EditUser {...props} />
  </Suspense>
);
export const DeleteUserSuspense = (props) => (
  <Suspense fallback={<LinearProgress />}>
    <DeleteUser {...props} />
  </Suspense>
);

export const InstancesSuspense = (props) => (
  <Suspense fallback={<LinearProgress />}>
    <Instances {...props} />
  </Suspense>
);

export const SimulationsSuspense = (props) => (
  <Suspense fallback={<LinearProgress />}>
    <Simulations {...props} />
  </Suspense>
);

export const SetUpSimulationSuspense = (props) => (
  <Suspense fallback={<LinearProgress />}>
    <SetUpSimulation {...props} />
  </Suspense>
);

export const ConfigureSimulationSuspense = (props) => (
  <Suspense fallback={<LinearProgress />}>
    <ConfigureSimulation {...props} />
  </Suspense>
);

export const EditSimulationSuspense = (props) => (
  <Suspense fallback={<LinearProgress />}>
    <EditSimulation {...props} />
  </Suspense>
);

export const AMISuspense = (props) => (
  <Suspense fallback={<LinearProgress />}>
    <AMIs {...props} />
  </Suspense>
);

export const BillingSuspense = (props) => (
  <Suspense fallback={<LinearProgress />}>
    <Billing {...props} />
  </Suspense>
);

export const BehavioralTestSuspense = (props) => (
  <Suspense fallback={<LinearProgress />}>
    <BehaviouralTest {...props} />
  </Suspense>
)

//#endregion
