import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
} from "@material-ui/core";

export default ({ dialog = {} }) => {
  const {
    name = "GlobalDialog",
    open = false,
    onClose = () => {},
    title = "",
    content = "",
    actions = [],
  } = dialog;

  return (
    <Dialog
      data-testid={`${name}.Dialog`}
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" data-testid={`${name}.DialogTitle`}>
        {title}
      </DialogTitle>
      {typeof content === "function" ? (
        content()
      ) : (
        <>
          <Box component={DialogContent} maxWidth="300px">
            <DialogContentText
              variant="body1"
              color="textPrimary"
              data-testid={`${name}.DialogContentText`}>
              {content}
            </DialogContentText>
          </Box>
          <DialogActions>
            {!!actions?.length &&
              actions.map(
                (action, index) =>
                  React.isValidElement(action) &&
                  React.cloneElement(action, {
                    key: index,
                  })
              )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
