import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import logo from "Layouts/images/logo-sestorobotics-white.png";
import Footer from "Layouts/Footer/Footer";
import Login from "Components/Login/Login";
import { Route, Switch } from "react-router-dom";

const labelStyle = {
  color: "var(--white)",
  display: "block",
  fontSize: "12px",
  letterSpacing: "1px",
};

const imgStyle = {
  height: "50px",
  margin: "0 auto",
  display: "block",
};

class LoginLayout extends Component {
  render() {
    document.title = "SESTO | Login";

    return (
      <Box
        component={Grid}
        container
        className="d-flex justify-content-center align-items-center"
        height="100vh"
        bgcolor="var(--theme)"
      >
        <Grid item xs={12} sm={8} md={5} className="d-flex flex-column">
          <Box component="img" src={logo} {...imgStyle} alt="" />
          <Box component="label" {...labelStyle} className="text-center">
            Autonomous Material Movement Technology
          </Box>
          <Switch>
            <Route path="/login" component={Login} />
          </Switch>
          <Footer />
        </Grid>
      </Box>
    );
  }
}

export default LoginLayout;
