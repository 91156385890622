import { Build, DesktopMac, Person, FileCopy, LocalAtm, ListAlt } from "@material-ui/icons";

export const adminRoutes = [
  {
    id: 1,
    path: "/users/list",
    isException: true,
    sidebarName: "Admin",
    navbarName: "Admin",
    icon: Person,
  },
  {
    id: 2,
    path: "/instances/list",
    isException: true,
    sidebarName: "AWS EC2 Management",
    navbarName: "AWS EC2 Management",
    icon: DesktopMac,
  },
  {
    id: 3,
    path: "/simulations/list",
    isException: true,
    sidebarName: "Simulation",
    navbaName: "Simulation",
    icon: Build,
  },
  {
    id: 4,
    path: "/billing/list",
    isException: true,
    sidebarName: "Dashboard",
    navbaName: "Dashboard",
    icon: LocalAtm,
  },
  {
    id: 5,
    path: "/amis/list",
    isException: true,
    sidebarName: "AWS AMI Management",
    navbarName: "AWS AMI Management",
    icon: FileCopy,
  },
];

export const userRoutes = [
  {
    id: 1,
    path: "/users/list",
    isException: true,
    sidebarName: "Admin",
    navbarName: "Admin",
    icon: Person,
  },
  {
    id: 2,
    path: "/instances/list",
    isException: true,
    sidebarName: "AWS EC2 Management",
    navbarName: "AWS EC2 Management",
    icon: DesktopMac,
  },
  {
    id: 3,
    path: "/simulations/list",
    isException: true,
    sidebarName: "Simulation",
    navbaName: "Simulation",
    icon: Build,
  },
  {
    id: 4,
    path: "/billing/list",
    isException: true,
    sidebarName: "Dashboard",
    navbaName: "Dashboard",
    icon: LocalAtm,
  },
  {
    id: 6,
    path: "/behavioraltest",
    isException: true,
    sidebarName: "Behavioral Test",
    navbarName: "Behavioral Test",
    icon: ListAlt,
  },
];

// export default adminRoutes;
