import initialState from "../../initialState";
import { GLOBAL_ERROR } from "../../actionTypes";

const errorReducer = (state = initialState.errors, action) => {
  switch (action.type) {
    case GLOBAL_ERROR:
      return { ...state, global: action.payload };
    default:
      return state;
  }
};

export default errorReducer;
