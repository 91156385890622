import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  CreateUserSuspense,
  EditUserSuspense,
  DeleteUserSuspense,
  UsersSuspense,
  InstancesSuspense,
  SimulationsSuspense,
  SetUpSimulationSuspense,
  EditSimulationSuspense,
  ConfigureSimulationSuspense,
  AMISuspense,
  BillingSuspense,
  BehavioralTestSuspense,
} from "./AppLayoutRoutes";

export default () => {
  return (
    <Switch>
      {/**Main Route*/}
      <Route
        key={1}
        path="/instances/list"
        exact
        component={InstancesSuspense}
      />
      <Route key={2} path="/users/list" exact component={UsersSuspense} />;
      <Route key={3} path="/user/create" exact component={CreateUserSuspense} />
      <Route key={5} path="/user/delete" exact component={DeleteUserSuspense} />
      <Route key={4} path="/user/:name" exact component={EditUserSuspense} />
      <Route
        key={6}
        path="/simulations/list"
        exact
        component={SimulationsSuspense}
      />
      <Route
        key={8}
        path="/simulation/setup"
        exact
        component={SetUpSimulationSuspense}
      />
      <Route
        key={10}
        path="/simulation/edit"
        exact
        component={EditSimulationSuspense}
      />
      <Route
        key={11}
        path="/simulation/configure"
        exact
        component={ConfigureSimulationSuspense}
      />
      <Route
        key={12}
        path="/amis/list"
        exact
        component={AMISuspense}
      />
      <Route 
        key={13}
        path="/billing/list"
        exact
        component={BillingSuspense}
      />
      <Route
        key={14}
        path="/behavioraltest"
        exact
        component={BehavioralTestSuspense}
      />
      {/**Not Found Route direct to list of users*/}
      <Route path="*" render={() => <Redirect to="/instances/list" />} />
    </Switch>
  );
};
