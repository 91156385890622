import * as actionTypes from "../../actionTypes";

const initialState = {
  info: {
    open: false,
    title: "",
    content: "",
    onClose: () => {},
  },
  action: {
    open: false,
    title: "",
    content: "",
    state: {},
    onClose: () => {},
    onOk: () => {},
  },
};

export default function globalDialogReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INFO_DIALOG:
      return { ...state, info: { ...action.payload } };
    case actionTypes.INFO_DIALOG_RESET:
      return { ...state, info: { ...initialState.info } };
    case actionTypes.ACTION_DIALOG:
      return { ...state, action: { ...action.payload } };
    case actionTypes.ACTION_DIALOG_RESET:
      return { ...state, action: { ...initialState.action } };
    case actionTypes.ACTION_DIALOG_STATE:
      return {
        ...state,
        action: {
          ...state.action,
          state: { ...state.action.state, ...action.payload },
        },
      };
    default:
      return state;
  }
}
