import axios from "axios";
import { handleResponse, handleError, handleRequest } from "./baseInterceptors";
import { localStorageHelper } from "../utils/StorageHelper";

export const hostname = window.location.hostname;

//Create new Axios instance
const instance = axios.create({
  baseURL: window.location.protocol + "//" + hostname + "/simulation",
});
//Set auth header if localStorage has it
const access_token = localStorageHelper.getItem("access_token");
if (access_token) {
  instance.defaults.headers.common["Authorization"] = "Bearer " + access_token;
}

//Set content-type as application/json
instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.headers.get["Content-Type"] = "application/json";
instance.defaults.headers.put["Content-Type"] = "application/json";
instance.defaults.headers.delete["Content-Type"] = "application/json";

//Set response interceptor
instance.interceptors.response.use(handleResponse, handleError);
//Set requet interceptor
instance.interceptors.request.use(handleRequest, function (error) {
  return Promise.reject(error);
});

export default instance;
