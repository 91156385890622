/**
 * @class A helper class for object checking and manipulation
 */
class ObjectHelper {
  /**
   * @param {*} obj: Object
   * @description check whether object passed in parameter is empty
   * @return boolean;
   */
  isEmpty(obj) {
    if (Object.getOwnPropertyNames(obj).length === 0) {
      return true;
    }
    return false;
  }

  /**
   * @param {*} obj: Object
   * @description check whether object passed in parameter is an array
   * @note [Native objectHelper.isArray but wrapped for centralizing]
   * @return boolean;
   */
  isArray(obj) {
    return Array.isArray(obj);
  }

  /**
   * @param {*} obj: Object
   * @description check whether object passed in parameter is a number
   * @return boolean;
   */
  isNumber(obj) {
    return typeof obj === "number";
  }

  /**
   * @param {*} obj: Object
   * @description check whether object passed in parameter is a string
   * @return boolean;
   */
  isString(obj) {
    return typeof obj === "string";
  }

  /**
   * @param {*} obj: Object
   * @description check whether object passed in parameter is truthy
   * @return boolean;
   */
  isTruthy(obj) {
    return !!obj;
  }

  /**
   * @param {*} obj: Object
   * @description check whether object passed in parameter is nullish (undefined and null)
   * @return boolean;
   */
  isNullish(obj) {
    return obj === undefined || obj === null;
  }

  /**
   * @param {*} obj: Object
   * @param {*} args: (n) number of string arguments
   * @description check whether object passed in parameter has deeply truthy with a structure
   * @return boolean;
   */
  isDeepTruthy(obj) {
    try {
      let targetObj = { ...obj };
      const deepProps = [...arguments]; //shallow clone
      deepProps.shift();
      for (const deepProp of deepProps) {
        if (!targetObj || !targetObj.hasOwnProperty(deepProp)) {
          return !!targetObj[deepProp];
        }
        targetObj = targetObj[deepProp];
      }
      return true;
    } catch (ex) {
      console.error(ex);
    }
  }

  /**
   * @param {*} obj: Object
   * @description check whether object has circular structure
   * @return boolean;
   */
  isCircularStructure(obj) {
    const seenObjects = [];
    if (obj && typeof obj === "object") {
      if (seenObjects.indexOf(obj) !== -1) {
        return true;
      }
      seenObjects.push(obj);
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && this.isCircularStructure(obj[key])) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * @param {*} arr
   * @description check if there is duplicate inside string/number array
   * @note THIS CANNOT CHECK FOR OBJECT ARRAYS
   */
  hasDuplicate(arr) {
    const setSize = new Set(arr).size;
    return arr.length > setSize;
  }

  /**
   * @param {*} num
   * @description convert number to fixed decimal place
   */
  toFixed(num, decimal) {
    if (isNaN(num) || typeof num !== "number") {
      return Number(0).toFixed(decimal);
    }
    num = num.toString();
    if (num.indexOf(".") > 0) {
      num = num.slice(0, num.indexOf(".") + decimal + 1);
    }
    return Number(num).toFixed(decimal);
  }

  /**
   * @param {*} num
   * @description convert string to fixed length followed by ...
   */
  toLength(string, length) {
    return string?.length < length ? string : string.slice(0, length) + "...";
  }

  /**
   * @param {*} num
   * @description try parsing the passed value, if cannot, just return original value;
   */
  tryParse(num) {
    try {
      const value = JSON.parse(num);
      return value;
    } catch {
      const value = num;
      return value;
    }
  }
}

export const objectHelper = new ObjectHelper();
