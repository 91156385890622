import * as actionTypes from "redux/actionTypes";
import initialState from "redux/initialState";
import * as loginApi from "api/loginApi";
import { localStorageHelper } from "utils/StorageHelper";
import jwtDecode from "jwt-decode";

export function loginSuccess(auth) {
  return { type: actionTypes.AUTH_LOGIN_SUCCESS, auth: auth };
}

export function logoutSuccess() {
  return { type: actionTypes.AUTH_LOGIN_SUCCESS, auth: initialState.auth };
}

export function loginReload(auth) {
  return function (dispatch) {
    dispatch(loginSuccess(auth));
  };
}

export function login(credentials) {
  return function (dispatch) {
    return loginApi
      .login(credentials)
      .then((token) => {
        if (!token) {
          throw new Error("Login failed. Please check your credentials.");
        }
        localStorageHelper.setItem("access_token", token.access_token);
        localStorageHelper.setItem("user", credentials.user_name);

        const decoded = jwtDecode(token.access_token);
        delete decoded?.identity?.password;

        const auth = {
          isLoggedIn: true,
          userName: credentials.user_name,
          ...decoded?.identity,
        };

        dispatch(loginSuccess(auth));
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
}

export function logout() {
  return function (dispatch) {
    dispatch(logoutSuccess());
  };
}
