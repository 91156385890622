import React, { Component } from "react";
import PropTypes from "prop-types";
import DefaultFallback from "./ErrorBoundaryFallback";

/**
 * @description A wrapper component for setting error boundary
 * to prevent UI crashes when rendering got runtime exceptions
 */
class ErrorBoundaryProvider extends Component {
  state = {
    error: null,
  };

  /**
   * @description Update state so next render shows fallback UI.
   */
  static getDerivedStateFromError(error) {
    const newError = new Error(
      `[ERROR] Caught an error at "${window.location.href}"
    \n[Name] : ${error.name}
    \n[Details] : ${error.message}`
    );

    console.error(error);
    return {
      error: newError,
    };
  }

  /**
   * @description // Perform action after catching error
   */
  componentDidCatch(error, info) {
    if (this.props.action) {
      this.props.action(this.state.error || error, info);
    }
  }

  render() {
    if (this.state.error) {
      // Provider can have a fallback props for custom UI
      if (this.props.fallback) {
        return this.props.fallback; // If no custom, use a default one
      }
      return (
        <DefaultFallback
          errorDetails={
            !!this.state.error && !!this.state.error.message
              ? this.state.error.message
              : "Unhandled exception happened. Please open console to check the error logs."
          }
        />
      );
    }
    return this.props.children;
  }

  static propTypes = {
    action: PropTypes.func,
    fallback: PropTypes.element,
  };
}

export default ErrorBoundaryProvider;
