import { combineReducers } from "redux";
import auth from "./auth/authReducer";
import ec2 from "./ec2/instanceReducer";
import admin from "./admin/adminReducer";
import errors from "./error/errorReducer";
import globalDialog from "./globalDialog/globalDialogReducer";
import path from "./layout/breadcrumbReducer";
import simulation from "./simulation/simulationReducer";
import billing from "./billing/billingReducer";
import graph from "./graph/graphReducer";
import ami from "./ami/amiReducer";

const rootReducer = combineReducers({
  auth,
  ec2,
  admin,
  errors,
  globalDialog,
  path,
  simulation,
  billing,
  graph,
  ami,
});
export default rootReducer;
