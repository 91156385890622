export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";

export const BREADCRUMB_PATH_CHANGED = "BREADCRUMB_PATH_CHANGED";

//Admin Api
export const CREATE_AUTHENTICATE = "CREATE_AUTHENTICATE";
export const CREATE_REFRESHEXISTING = "CREATE_REFRESHEXISTING";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETED_USER = "DELETED_USER";
export const RECEIVED_USER = "RECEIVED_USER";

// Ec2 Api
export const RECEIVED_INSTANCE = "RECEIVED_INSTANCE";
export const RECEIVED_DB_INSTANCE = "RECEIVED_DB_INSTANCE";

// Simulation Api
export const RECEIVED_VERSION = "RECEIVED_VERSION";
export const RECEIVED_GAZEBO_WORLD = "RECEIVED_GAZEBO_WORLD";

// Ami Api
export const RECEIVED_AMI = "RECEIVED_AMI";
export const RECEIVED_ALL_AMI_VERSIONS = "RECEIVED_ALL_AMI_VERSIONS";
export const RECEIVED_ALL_OUTDATED_VERSIONS = "RECEIVED_OUTDATED_AMI_VERSIONS";
export const UPDATE_OUTDATED_VERSIONS = "UPDATE_OUTDATED_VERSIONS";
export const CREATE_AMI = "CREATE_AMI";

//Billing Api
export const RECEIVED_BILLING = "RECEIVED_BILLING";
export const RECEIVED_DATES = "RECEIVED_DATES";
export const RECEIVED_STATS = "RECEIVED_STATS";
export const RECEIVED_RATES = "RECEIVED_RATES";
export const UPDATE_RATES = "UPDATE_RATES"

// Graph Api
export const RECEIVED_GRAPH = "RECEIVED_GRAPH";

// Summary
export const RECEIVED_SUMMARY = "RECEIVED_SUMMARY";
export const GLOBAL_ERROR = "GLOBAL_ERROR";
export const INFO_DIALOG = "INFO_DIALOG";
export const INFO_DIALOG_RESET = "INFO_DIALOG_RESET";
export const ACTION_DIALOG = "ACTION_DIALOG";
export const ACTION_DIALOG_RESET = "ACTION_DIALOG_RESET";
export const ACTION_DIALOG_STATE = "ACTION_DIALOG_STATE";
