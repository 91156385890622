import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";

import { useHistory, useLocation } from "react-router";
import { makeStyles, useMediaQuery } from "@material-ui/core";

const NavLink = ({ to, children }) => {
  const history = useHistory();
  const location = useLocation();
  const handleDirectToPath = () => {
    history.push(to);
  };

  const isActiveRoute = location.pathname.indexOf(to) > -1 ? true : false;

  return (
    <div
      onClick={handleDirectToPath}
      className={`m-1 ${isActiveRoute && "text-light-theme bg-white-smoke"}`}
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles({
  expandedIcon: {
    minWidth: "58px",
  },
});

export default ({
  color,
  key: keyProp,
  state,
  location,
  onClick,
  isCollapsed: isCollapsedProp,
  ...prop
}) => {
  const classes = useStyles() ?? {};
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const isCollapsed = isCollapsedProp && mdUp;

  return (
    <div>
      <NavLink to={prop.path} activeClassName="active" key={keyProp}>
        <ListItem button>
          <ListItemIcon
            classes={{ root: !isCollapsed && classes.expandedIcon }}
          >
            {typeof prop.icon === "string" ? (
              <Icon htmlColor="var(--theme)">{prop.icon}</Icon>
            ) : (
              <prop.icon htmlColor="var(--theme)" />
            )}
          </ListItemIcon>
          {!isCollapsed && (
            <Box
              component={ListItemText}
              color="var(--theme)"
              fontWeight="500"
              primary={prop.sidebarName}
              disableTypography={true}
            />
          )}
        </ListItem>
      </NavLink>
    </div>
  );
};
