import React, { useRef, useEffect } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { errorBoundaryStyle } from "./errorBoundaryStyle";
import { FileCopy } from "@material-ui/icons";

import { ExpandMore } from "@material-ui/icons";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { dispatchGlobalError } from "redux/actions/errorAction";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(errorBoundaryStyle);

const ErrorBoundaryFallback = ({ errorDetails }) => {
  const classes = useStyles();
  const errorRef = useRef(null);
  const dispatch = useDispatch();

  /**
   * @description handling copy error contents
   */
  const handleCopy = () => {
    if (errorRef.current && errorRef.current.innerText) {
      navigator.clipboard.writeText(errorRef.current.innerText);
      toast.success("Copied to clipboard.");
    }
  };

  /**
   * @description dispatch to empty the global error on page change
   */
  const handleCleanUpError = () => {
    dispatchGlobalError(null)(dispatch);
  };

  useEffect(() => {
    window.addEventListener("popstate", handleCleanUpError);

    return () => {
      window.removeEventListener("popstate", handleCleanUpError);
    };
  });

  return (
    <>
      {errorDetails && (
        <div className={classes.layoutContainer}>
          <div className={classes.errorTitle}>Oops!</div>
          <div className={classes.errorMessage}>Something went wrong...</div>

          <div className={classes.errorDetails}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography>Click here to view error details</Typography>
              </AccordionSummary>

              <AccordionDetails className={classes.errorAccordion}>
                <div>
                  <IconButton className={classes.copyIcon} onClick={handleCopy}>
                    <FileCopy />
                  </IconButton>
                </div>
                <Typography ref={errorRef}>{errorDetails}</Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className={classes.errorRecovery}>
            <div
              onClick={() => {
                window.location.href = "/";
              }}>
              Go back to home page
            </div>
          </div>
        </div>
      )}
      {!errorDetails && <Redirect to="/" />}
    </>
  );
};

export default ErrorBoundaryFallback;
