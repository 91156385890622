import * as actionTypes from "../../actionTypes";
import initialState from "../../initialState";

export default function simulationReducer(
  state = initialState.simulation,
  action
) {
  switch (action.type) {
    case actionTypes.RECEIVED_VERSION:
      return {
        ...state,
        versions: action.data
          ? JSON.parse(JSON.stringify(action.data))
          : initialState.simulation.versions,
      };

    case actionTypes.RECEIVED_GAZEBO_WORLD:
      return {
        ...state,
        gazeboWorlds: action.data
          ? JSON.parse(JSON.stringify(action.data))
          : initialState.simulation.gazeboWorlds,
      };

    default:
      return state;
  }
}
