import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as loginActions from "redux/actions/loginActions";
import { bindActionCreators } from "redux";
import {
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Button,
  Grid,
  Box,
  Card,
  MuiThemeProvider,
  TextField,
} from "@material-ui/core";
import { Person, Lock } from "@material-ui/icons";
import { Redirect } from "react-router-dom";

import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { localStorageHelper } from "utils/StorageHelper.js";

const loginTitleStyle = {
  color: "#444444 !important",
  display: "block",
  fontSize: "2.125rem",
  fontWeight: 600,
};

const loginSubtitleStyle = {
  color: "#999999",
  display: "block",
  fontSize: "1rem",
  marginTop: "3px",
};

const checkboxLabelStyle = {
  color: "#888",
  fontSize: "15px !important",
  fontWeight: "500 !important",
};

const injectTheme = (outerTheme) => ({
  ...outerTheme,
  overrides: {},
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      vertical: "top",
      horizontal: "center",
      isChecked: false,
      loader: false,

      dirty: {
        username: false,
        password: false,
      },
    };

    this.validator = new SimpleReactValidator({ locale: "en" });
  }

  componentDidMount() {
    if (localStorageHelper.getItem("isChecked") === true) {
      this.setState({
        isChecked: true,
        username: localStorageHelper.getItem("username"),
        password: localStorageHelper.getItem("password"),
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      isChecked: false,
      dirty: {
        ...this.state.dirty,
        [e.target.name]: true,
      },
    });
  };

  rememberMeChange = (e) => {
    this.setState({
      isChecked: !this.state.isChecked,
    });

    if (!this.state.isChecked) {
      //TODO: this is dangerous, need more secure way to remember session
      localStorageHelper.setItem("username", this.state.username);
      localStorageHelper.setItem("password", this.state.password);
      localStorageHelper.setItem("isChecked", !this.state.isChecked);
    } else {
      this.setState({
        username: "",
        password: "",
      });
      localStorageHelper.clear();
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ dirty: { username: true, password: true } });
    if (!this.validator.allValid()) {
      return toast.error("Please complete the login form.");
    }
    try {
      this.setState({ loader: true });
      const credentials = {
        user_name: this.state.username,
        password: this.state.password,
      };
      await this.props.authActions.login(credentials);
      this.props.history.push("/#/instances/list");
    } catch (ex) {
      toast.error(ex.message || "Login failed");
      this.setState({ loader: false });
    }
  };

  getValidatorError = (nameOfStateProp) => {
    try {
      const allErrorMessages = this.validator.getErrorMessages();
      return this.state.dirty?.[nameOfStateProp]
        ? allErrorMessages[nameOfStateProp]
        : "";
    } catch (ex) {
      return "";
    }
  };

  render() {
    const { username, password } = this.state;

    if (!!this.props.auth?.isLoggedIn) {
      if (this.props.location?.state?.referrer) {
        return <Redirect to={this.props.location.state.referrer} />
      } else {
        return <Redirect to="/instances/list" />
      }
    }

    return (
      <Box
        component={Card}
        className="d-flex justify-content-center align-items-center p-sm-4 p-2"
        marginTop="30px"
        marginBottom="20px"
      >
        <form onSubmit={this.handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Box {...loginTitleStyle} className="text-center">
                Login
              </Box>
            </Grid>
            <Grid item xs={12} className="p-2">
              <Box
                {...loginSubtitleStyle}
                className="text-center px-4"
              >
                SESTO Robotics is a leading robotics company in the field of
                autonomous technology.
              </Box>
            </Grid>

            <MuiThemeProvider theme={injectTheme}>
              <Grid container spacing={1} className="p-4 w-100">
                <Grid item xs={1} className="d-flex align-items-center">
                  <Person />
                </Grid>
                <Grid item xs={11}>
                  {this.validator.message(
                    "username",
                    this.state.username,
                    "required"
                  )}
                  <TextField
                    fullWidth
                    variant="outlined"
                    onChange={this.handleChange}
                    name="username"
                    label="Username"
                    value={username}
                    InputProps={{
                      inputProps: {
                        "data-testid": "Login.Username",
                      },
                    }}
                    error={!!this.getValidatorError("username")}
                    className="mb-2"
                  />
                  <span className="text-hint text-danger">
                    {this.getValidatorError("username") || <>&nbsp;</>}
                  </span>
                </Grid>
                <Grid item xs={1} className="d-flex align-items-center">
                  <Lock />
                </Grid>
                <Grid item xs={11}>
                  {this.validator.message(
                    "password",
                    this.state.password,
                    "required"
                  )}
                  <TextField
                    fullWidth
                    variant="outlined"
                    onChange={this.handleChange}
                    name="password"
                    type="password"
                    label="Password"
                    value={password}
                    InputProps={{
                      inputProps: {
                        "data-testid": "Login.Password",
                      },
                    }}
                    error={!!this.getValidatorError("password")}
                  />
                  <span className="text-hint text-danger">
                    {this.getValidatorError("password") || <>&nbsp;</>}
                  </span>
                </Grid>
                <Grid item md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        labelstyle={{ color: "gray" }}
                        iconstyle={{ fill: "gray" }}
                        inputstyle={{ color: "gray" }}
                        style={{ color: "gray" }}
                        disabled={
                          this.state.username === "" &&
                            this.state.username === ""
                            ? true
                            : false
                        }
                        value={`${this.state.isChecked}`}
                        checked={
                          !this.state.username === "" &&
                            !this.state.password === ""
                            ? true
                            : this.state.isChecked
                        }
                        onChange={this.rememberMeChange}
                        onClick={this.handleGet}
                      />
                    }
                    label={
                      <Box component="span" {...checkboxLabelStyle}>
                        Remember Me
                      </Box>
                    }
                  />
                </Grid>
                <Grid item xs={12} className="py-4">
                  <Button
                    fullWidth
                    data-testid="Login.Button"
                    className="bg-theme p-2"
                    color="primary"
                    variant="contained"
                    type="submit"
                    disableElevation
                    disabled={this.state.loader}
                  >
                    <b>{!this.state.loader ? "Login" : "Logging in..."}</b>
                  </Button>
                  {this.state.loader && (
                    <Box
                      className="w-100 d-flex justify-content-center align-items-center"
                      mt="-30px"
                    >
                      <CircularProgress size={24} />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </MuiThemeProvider>
          </Grid>
        </form>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(loginActions, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Login);
