import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";

import MenuIcon from "@material-ui/icons/Menu";
import Box from "@material-ui/core/Box";
import HeaderLinks from "./HeaderLinks.js";
import logoImg from "../images/logo-sestorobotics-white.png";

function Header({ ...props }) {
  return (
    <AppBar elevation={0} position="fixed">
      <Toolbar>
        {
          //Mobile Menu Button
          <Hidden mdUp>
            <IconButton color="inherit" onClick={props.handleDrawerToggle}>
              <MenuIcon htmlColor="white" />
            </IconButton>
          </Hidden>
        }

        {
          //Menu Button
          <Hidden smDown>
            <IconButton color="inherit" onClick={props.handleDrawerClose}>
              <MenuIcon htmlColor="white" />
            </IconButton>
          </Hidden>
        }

        {
          //Logo
          <a href="#/">
            <Box component="img" width="200px" src={logoImg} alt="SESTO" />
          </a>
        }

        {
          //Avatar
          <div className="w-100 d-flex justify-content-end align-items-center">
            <HeaderLinks />
          </div>
        }
      </Toolbar>
    </AppBar>
  );
}

export default Header;
