import * as actionTypes from "../../actionTypes";
import initialState from "../../initialState";

export default function adminReducer(state = initialState.admin, action) {
  switch (action.type) {
    case actionTypes.RECEIVED_USER:
      return {
        ...state,
        users: action.data
          ? JSON.parse(JSON.stringify(action.data))
          : initialState.admin.users,
      };

    default:
      return state;
  }
}
