import axios from "./base";

export function login(credentials) {
  return axios.post("/auth", credentials).then((data) => {
    if (!!data && !!data.access_token) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + data.access_token;
      return data;
    }
  });
}
