import * as actionTypes from "../../actionTypes";
import initialState from "../../initialState";

export default function instanceReducer(state = initialState.ec2, action) {
  switch (action.type) {
    case actionTypes.RECEIVED_INSTANCE:
      return {
        ...state,
        instances: action.data
          ? JSON.parse(JSON.stringify(action.data))
          : initialState.ec2.instances,
      };
    case actionTypes.RECEIVED_DB_INSTANCE:
      return {
        ...state,
        dbInstances: action.data
          ? JSON.parse(JSON.stringify(action.data))
          : initialState.ec2.dbInstances,
      };

    default:
      return state;
  }
}
