export async function handleResponse(response) {
  if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 202 ||
    response.status === 204
  )
    return response.data;
  if (response.status === 400) {
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

export function handleError(error) {
  //Network error
  if (error.message === "Network Error") {
    console.error("Unable to reach API. " + error);
    throw error;
  }
  //API error
  if (!!error && !!error.response && !!error.response.data) {
    console.error("API call failed. " + error);
    throw error.response.data;
  }
  if (error?.name === "PERMISSION_ERROR") {
    throw error;
  }
  console.error(error);
}

export function handleRequest(request) {
  return request;
}
