import { objectHelper } from "./ObjectHelper";

/**
 * @class A helper class for managing browser localStorage and then stringify and parsing
 */
class LocalStorageHelper {
  /**
   *
   * @param {*} key
   * @param {*} value
   * @description setItem
   */
  setItem(key, value) {
    try {
      if (typeof value === "string") {
        localStorage.setItem(key, value);
        return;
      }
      if (typeof value === "boolean") {
        localStorage.setItem(key, JSON.stringify(value));
        return;
      }
      if (
        typeof value === "object" &&
        !objectHelper.isCircularStructure(value)
      ) {
        localStorage.setItem(key, JSON.stringify(value));
        return;
      }
      if (
        typeof value === "number" &&
        !objectHelper.isCircularStructure(value)
      ) {
        localStorage.setItem(key, JSON.stringify(value));
        return;
      }
      throw new Error(`Failed to set below item to localStorage Key: ${key}`);
    } catch (ex) {
      console.error(ex);
      console.log(value);
    }
  }

  /**
   *
   * @param {*} key
   * @description getItem
   * @return value
   */
  getItem(key) {
    const value = localStorage.getItem(key);
    try {
      if (value === null) {
        return;
      }
      const parsedValue = JSON.parse(value);
      return parsedValue;
    } catch {
      if (typeof value === "string") {
        return value;
      }
      console.error("Failed to parse below item.");
      console.log(value);
    }
  }

  /**
   *
   * @param {*} key
   * @description removeItem
   */
  removeItem(key) {
    try {
      return localStorage.removeItem(key);
    } catch {
      console.error("Failed to remove below item with Key :" + key);
    }
  }

  /**
   * @description clear
   */
  clear() {
    localStorage.clear();
  }
}

export const localStorageHelper = new LocalStorageHelper();
