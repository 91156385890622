import { localStorageHelper } from "utils/StorageHelper";
import jwtDecode from "jwt-decode";
import { loginSuccess, logoutSuccess } from "redux/actions/loginActions";
import { store } from "redux/configureStore";

export const checkSession = () => {
  try {
    const jwt = localStorageHelper.getItem("access_token");
    if (!!jwt) {
      const decoded = jwtDecode(jwt);
      const currentDate = Date.now() / 1000;

      if (decoded.exp > currentDate) {
        delete decoded?.identity?.password;

        const auth = {
          isLoggedIn: true,
          userName: decoded?.identity?.user_name,
          ...decoded?.identity,
        };
        store.dispatch(loginSuccess(auth));
      } else {
        store.dispatch(logoutSuccess());
      }
    }
  } catch (ex) {
    console.error(ex);
  }
};
