import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import AuthenticatedSidebar from "./SidebarAuth";
import logoImg from "../images/logo-sestorobotics-theme.png";
import { Avatar, Divider, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import Scrollbars from "react-custom-scrollbars";

const RouteLinks = ({ routes = [], color, isCollapsed, ...props }) => (
  <Box component={List} paddingBottom="80px !important">
    {routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }

      return (
        <React.Fragment key={key}>
          <AuthenticatedSidebar
            {...prop}
            isCollapsed={isCollapsed}
            color={color}
            location={props?.location}
            onClick={props?.handleClick}
            state={props?.state}
          />
        </React.Fragment>
      );
    })}
  </Box>
);

class Sidebar extends React.Component {
  state = {};

  handleClick = (e) => () => {
    this.setState({ [e]: !this.state[e] });
  };

  getRoleName = (name) => {
    switch (name) {
      case "sesto_admin":
        return "Administrator";
      case "client_admin":
        return "Administrator";
      case "partner_admin":
        return "Administrator";
      case "engineer":
        return "Engineer";
      case "technician":
        return "Technician";
      case "operator":
        return "Operator";
      default:
        return "";
    }
  };
  
  render() {
    const { adminRoutes, userRoutes, color, isCollapsed, location } = this.props;
    const routes = this.props.authUser.userName === "sesto" 
      ? adminRoutes
      : userRoutes;
    return (
      <>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor="left"
            open={this.props.open}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {
              //Logo
              <Box padding="16px">
                <a href="#/">
                  <Box
                    component="img"
                    width="200px"
                    src={logoImg}
                    alt="SESTO"
                  />
                </a>
              </Box>
            }
            <RouteLinks
              routes={routes}
              color={color}
              isCollapsed={isCollapsed}
              location={location}
              handleClick={this.handleClick}
              state={this.state}
            />
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Box
            component={Scrollbars}
            autoHide
            borderRight="solid 1px var(--gray)"
            height="100vh !important"
            width="unset !important"
            bgcolor="#FFFFFF"
            minWidth={isCollapsed ? "75px" : "260px"}
          >
            {!isCollapsed && (
              <>
                <div className="p-2">
                  <Box
                    bgcolor="var(--white)"
                    className="w-100 d-flex"
                    height="80px"
                    borderRadius="4px"
                  >
                    <div className="p-2 py-3">
                      <Avatar />
                    </div>
                    <div className="p-2 py-3">
                      <Typography>
                        <span className="text-dark font-weight-light-bold">{`${
                          this.props.authUser?.first_name ?? ""
                        } ${this.props.authUser?.last_name ?? ""}`}</span>
                        <br />
                      </Typography>
                    </div>
                  </Box>
                </div>
                <Divider />
              </>
            )}
            <RouteLinks
              routes={routes}
              color={color}
              isCollapsed={isCollapsed}
              location={location}
              handleClick={this.handleClick}
              state={this.state}
            />
          </Box>
        </Hidden>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state?.auth,
  };
};

export default connect(mapStateToProps)(Sidebar);
