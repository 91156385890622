// ##############################
// Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 225;
const drawerWidthClosed = 60;

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

const container = {
  marginRight: "auto",
  marginLeft: "auto",
};

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em",
};

const lightThemeColor = "#5492AB";
const themeColor = "#3E5964";
const sidebarColor = "#FFFFFF";
const primaryColor = "#3E5964"; //"#9C27B0";
const yellowColor = "#f7df1e";
const warningColor = "#FF9800";
const dangerColor = "#f43636";
const headerColor = "#f9f9f9";
const successColor = "#4CAF50";
const infoColor = "#00ACC1";
const roseColor = "#E91E63";
const whiteColor = "#FFFFFF";
const whiteSmokeColor = "#F2F2F2";
const wildSandColor = "#F6F6F6";
const gallerySmokeColor = "#F0F0F0";
const blackColor = "#000000";
const mineShaftColor = "#333333";
const grayColor = "#999999";
const darkgrayColor = "#404040";
const graySmokeColor = "#EDEDEE";
const plaingraySmokeColor = "#E9E9E9";
const lightGrayColor = "#E1E1E1";
const lightGraySmokyColor = "#CCCCCC";
const galleryColor = "#EBEBEB";
const lightGalleryColor = "#EEEEEE";
const grayWhiteColor = "#F8F8F8";
const silverColor = "#BBBBBB";
const silverChaliceColor = "#AAAAAA";
const selagoColor = "#F4F8FD";
const boulderColor = "#777777";
const steelblueColor = "#4682B4";
const ceruleanColor = "#009DDE";
const tundoraColor = "#444444";
const doveGrayColor = "#6F6F6F";
const emperorColor = "#555555";
const alabasterColor = "#FAFAFA";
const aquaHazeColor = "#EBF1F4";
const lightBlueColor = "#E5F1F6";
const fiordColor = themeColor;

export {
  themeColor,
  sidebarColor,
  yellowColor,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  whiteColor,
  whiteSmokeColor,
  wildSandColor,
  gallerySmokeColor,
  blackColor,
  mineShaftColor,
  grayColor,
  darkgrayColor,
  graySmokeColor,
  grayWhiteColor,
  plaingraySmokeColor,
  lightGrayColor,
  lightGraySmokyColor,
  galleryColor,
  lightGalleryColor,
  silverColor,
  silverChaliceColor,
  selagoColor,
  boulderColor,
  steelblueColor,
  lightBlueColor,
  ceruleanColor,
  tundoraColor,
  doveGrayColor,
  emperorColor,
  alabasterColor,
  aquaHazeColor,
  fiordColor,
  lightThemeColor,
  drawerWidth,
  drawerWidthClosed,
  transition,
  headerColor,
  container,
  defaultFont,
};
