import {
  whiteColor,
  whiteSmokeColor,
  graySmokeColor,
  ceruleanColor,
} from "theme/default";

export const errorBoundaryStyle = (theme) => ({
  layoutContainer: {
    background: "#3E555E",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  errorTitle: {
    color: whiteColor,
    fontSize: "80px",
  },
  errorMessage: {
    color: whiteSmokeColor,
    fontSize: "30px",
  },
  errorDetails: {
    padding: "5px",
    maxWidth: "300px",
  },
  errorAccordion: {
    margin: "5px",
    background: graySmokeColor,
    display: "flex",
    flexDirection: "column",
    "& div": {
      display: "flex",
      justifyContent: "flex-end",
    },
    "& p": {
      whiteSpace: "pre-wrap",
    },
  },
  errorRecovery: {
    marginTop: "10px",
    "& div": {
      cursor: "pointer",
      color: ceruleanColor,
      textDecoration: "underline",
    },
  },
  copyIcon: {
    padding: "2px",
    borderRadius: "5px",
  },
});
