import { defaultFont, container, grayColor } from "../../theme/default";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "15px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    ...defaultFont,
    fontWeight: "600",
    fontSize: "12px",
  },
  left: {
    // float: "left!important",
    display: "block",
    fontSize: "11px",
    fontWeight: "500",
    color: grayColor,
    margin: 0,
  },
  right: {
    padding: "15px 0",
    margin: "0",
    fontSize: "14px",
    float: "right!important",
  },
  footer: {
    bottom: "0",
    zindex: 10000,
    // borderTop: "1px solid #e7e7e7",
    padding: 0,
    ...defaultFont,
  },

  a: {
    color: grayColor,
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  container: {
    ...container,
    textAlign: "center",
  },
};
export default footerStyle;
