import * as actionTypes from "../../actionTypes";
import initialState from "../../initialState";

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case actionTypes.AUTH_LOGIN_SUCCESS:
      return { ...action.auth };
    default:
      return state;
  }
}
