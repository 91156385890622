import React from "react";

import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { resetInfoDialog } from "redux/actions/globalDialogActions";
import GlobalDialog from "../GlobalDialog/GlobalDialog";

export default () => {
  const dialog = useSelector((state) => state.globalDialog?.info ?? {});
  const dispatch = useDispatch();

  const handleClose = () => {
    resetInfoDialog()(dispatch);
  };

  const dialogProps = {
    name: "InfoDialog",
    ...dialog,
    onClose: handleClose,
    actions: [
      <Button
        data-testid="InfoDialog.OkButton"
        onClick={handleClose}
        color="secondary"
        disableElevation
        variant="contained">
        OK
      </Button>,
    ],
  };

  return <GlobalDialog dialog={dialogProps} />;
};
