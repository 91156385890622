import React from "react";

import Button from "@material-ui/core/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { resetActionDialog } from "redux/actions/globalDialogActions";
import GlobalDialog from "../GlobalDialog/GlobalDialog";

export default () => {
  const dialog = useSelector((state) => state.globalDialog?.action ?? {});
  const dispatch = useDispatch();

  const handleClose = () => {
    if (dialog?.onClose instanceof Function) {
      dialog.onClose();
    }
    resetActionDialog()(dispatch);
  };

  const handleOk = () => {
    if (dialog?.onOk instanceof Function) {
      dialog.onOk();
    }
    resetActionDialog()(dispatch);
  };

  const dialogProps = {
    name: "ActionDialog",
    ...dialog,
    onClose: handleClose,
    actions: dialog?.actions ?? [
      <Button
        data-testid="ActionDialog.CancelButton"
        onClick={handleClose}
        color="inherit"
        variant="outlined">
        Cancel
      </Button>,
      <Button
        data-testid="ActionDialog.OkButton"
        onClick={handleOk}
        color="secondary"
        variant="contained"
        disableElevation>
        Confirm
      </Button>,
    ],
  };

  return <GlobalDialog dialog={dialogProps} />;
};
