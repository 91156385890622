import React from "react";
import { Breadcrumbs, Link, Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export default (props) => {
  const navigations = useSelector((state) => state?.path) ?? [];

  return (
    <Box className="px-1 px-sm-3 pt-sm-4" marginBottom={["0px", "-20px"]}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="default" />}>
        {!!navigations.length &&
          navigations.map((nav, index) => {
            return (
              <Link
                key={index}
                color={"inherit"}
                href={nav?.path}
                onClick={nav?.handleClick}
              >
                {nav?.label}
              </Link>
            );
          })}
      </Breadcrumbs>
    </Box>
  );
};
