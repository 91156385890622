import * as actionTypes from "../../actionTypes";
import initialState from "../../initialState";

export default function billingReducer(state = initialState.billing, action) {
  switch (action.type) {
    case actionTypes.RECEIVED_BILLING:
      return {
        ...state,
        billings: action.data
          ? JSON.parse(JSON.stringify(action.data))
          : initialState.billing.billings,
      };
    
    case actionTypes.RECEIVED_DATES:
      return {
        ...state,
        dates: action.data
          ? JSON.parse(JSON.stringify(action.data))
          : initialState.billing.dates,
      };

    case actionTypes.RECEIVED_STATS:
      return {
        ...state,
        stats: action.data
          ? JSON.parse(JSON.stringify(action.data))
          : initialState.billing.stats,
      };
    
    case actionTypes.RECEIVED_RATES:
      return {
        ...state,
        rates: action.data
          ? JSON.parse(JSON.stringify(action.data))
          : initialState.billing.rates,
      };

    default:
      return state;
  }
}