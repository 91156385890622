/**
 * @constant ROUTE_TITLES
 *  - Object literal constant to fetch route title
 */
export const ROUTE_TITLES = Object.freeze({
  //User related routes
  "/users/list": "Users",
  "/user/:handle": "Configuration User",
  "/user/delete": "Delete User",

  // AWS EC2 Management related routes
  "/instances/list": "Instances",

  // Simulation related routes
  "/simulations/list": "Simulations",
  "/simulation/setup": "Setup Simulation",
  "/simulation/edit": "Edit Simulation",
  "/simulation/configure": "Configure Simulation",

  // AMI Management related routes
  "/ami/list": "AMIs",

  // Billing related routes
  "/billing/list": "Billings",
});
