import * as actionTypes from "../../actionTypes";
import initialState from "../../initialState";

export default function amiReducer(state = initialState.ami, action) {
  switch (action.type) {
    case actionTypes.RECEIVED_AMI:
      return {
        ...state,
        amis: action.data
          ? JSON.parse(JSON.stringify(action.data))
          : initialState.ami.amis,
      };

    case actionTypes.RECEIVED_ALL_AMI_VERSIONS:
      return {
        ...state,
        allVersions: action.data
          ? JSON.parse(JSON.stringify(action.data))
          : initialState.ami.allVersions,
      };

    case actionTypes.RECEIVED_ALL_OUTDATED_VERSIONS:
      return {
        ...state,
        outdatedVersions: action.data
          ? JSON.parse(JSON.stringify(action.data))
          : initialState.ami.outdatedVersions,
      };

    default:
      return state;
  }
}
