import React from "react";
import { render } from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import "index.css";
import App from "App";
import { store } from "redux/configureStore";
import { Provider as ReduxProvider } from "react-redux";
// Polyfills for cross-browser supports
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/promise/all-settled";

render(
  <ReduxProvider store={store}>
    <Router>
      <App />
    </Router>
  </ReduxProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
