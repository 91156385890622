import * as actionTypes from "../../actionTypes";
import initialState from "../../initialState";

export default function graphReducer(state = initialState.graph, action) {
  switch (action.type) {
    case actionTypes.RECEIVED_GRAPH:
      return {
        ...state,
        graph: action.data
          ? JSON.parse(JSON.stringify(action.data))
          : initialState.graph.graph,
      };

    default:
      return state;
  }
}
