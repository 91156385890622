export default {
  auth: {
    isLoggedIn: false,
    userName: undefined,
  },
  admin: {
    users: [],
  },
  ec2: {
    instances: [],
    dbInstances: [],
  },
  simulation: {
    versions: null,
    gazeboWorlds: null,
  },
  ami: {
    amis: [],
    allVersions: [],
    outdatedVersions: [],
  },
  billing: {
    billings: [],
    dates: {},
    stats: {},
    rates: {},
  },
  graph: {
    graph: null,
  },
  path: {},
  errors: {
    global: null,
  },
};
