import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppLayout from "Layouts/App/AppLayout";
import LoginLayout from "Layouts/Login/LoginLayout";
import ErrorBoundaryProvider from "Components/shared/ErrorBoundary/ErrorBoundaryProvider";
import ErrorBoundaryFallback from "Components/shared/ErrorBoundary/ErrorBoundaryFallback";
import { objectHelper } from "utils/ObjectHelper";
import { dispatchGlobalError } from "redux/actions/errorAction";
import { LOGIN_MODES } from "constants/LOGIN_MODES";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { mainTheme } from "theme/main";
import InfoDialog from "Components/shared/InfoDialog/InfoDialog";
import ActionDialog from "Components/shared/ActionDialog/ActionDialog";
import { checkSession } from "utils/SessionHelper";

function App() {
  const { isLoggedIn: authIsLoggedIn } = useSelector((state) => state.auth);
  const globalError = useSelector((state) => state.errors.global);
  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    checkSession();
  }, []);

  React.useEffect(() => {
    const handleRejection = (e) => {
      if (e.message) {
        const error = new Error(
          `[ERROR] Caught an error at "${window.location.href}"
        \n[Name] : ${e.name}
        \n[Details] : ${e.message}`
        );
        dispatchGlobalError(error)(dispatch);
        window.location.href = "/#/error";
        return;
      }
      if (
        objectHelper.isTruthy(e.reason) &&
        objectHelper.isString(e.reason.toString()) &&
        objectHelper.isArray(e.reason.toString().split(":")) &&
        e.reason.toString().split(":")[0] === "TypeError" //Can extend to other type of errors
      ) {
        const promiseError = new Error(
          `[ERROR] Caught a promise error at "${window.location.href}"
        \n[Name] : ${e.reason.toString().split(":")[0]}
        \n[Details] : ${e.reason.toString().split(":")[1]}`
        );
        dispatchGlobalError(promiseError)(dispatch);
        window.location.href = "/#/error";
      }
    };

    window.addEventListener("unhandledrejection", handleRejection);

    return () => {
      window.removeEventListener("unhandledrejection", handleRejection);
    };
  });

  const handleGlobalError = (error, info) => {
    console.error(error);
    console.log(info);
    dispatchGlobalError(error)(dispatch);
    window.location.href = "/#/error";
  };

  const getLoginModes = () => {
    if (!!authIsLoggedIn) {
      return LOGIN_MODES.AppLogin;
    }

    return LOGIN_MODES.NotLogin;
  };

  const renderRoutes = () => {
    const loginMode = getLoginModes();
    switch (loginMode) {
      case LOGIN_MODES.AppLogin:
        return <Route path="/" component={AppLayout} />;
      case LOGIN_MODES.NotLogin:
        return <Redirect
          to={{
            pathname: "/login",
            state: { referrer: location },
          }} />;
      default:
        return <Redirect
          to={{
            pathname: "/login",
            state: { referrer: location },
          }} />;
    }
  };

  return (
    <MuiThemeProvider theme={mainTheme}>
      <CssBaseline />
      <ToastContainer autoClose={3000} />
      <ErrorBoundaryProvider
        action={handleGlobalError}
        fallback={
          <ErrorBoundaryFallback
            errorDetails={globalError ? globalError.message : ""}
          />
        }
      >
        <InfoDialog />
        <ActionDialog />
        <Switch>
          <Route exact path="/login" component={LoginLayout} />
          <Route
            exact
            path="/error"
            render={() => (
              <ErrorBoundaryFallback
                errorDetails={globalError ? globalError.message : ""}
              />
            )}
          />
          {renderRoutes()}
        </Switch>
      </ErrorBoundaryProvider>
    </MuiThemeProvider>
  );
}

export default App;
